import { useMemo } from 'react';

import type { KinematicState } from '@sb/routine-runner';
import { convertEulerPose, convertJointAngles } from '@sbrc/utils';

import type {
  ControlModeState,
  TargetJointAnglesState,
  TargetPoseState,
} from './shared';

export function useGhostRobotState(
  controlMode: ControlModeState,
  isControllingLiveRobot: boolean,
  targetJointAnglesDegrees: TargetJointAnglesState,
  targetPose: TargetPoseState,
): Partial<KinematicState> | null {
  return useMemo(() => {
    if (controlMode === 'jointControlDual' || controlMode === 'space') {
      const jointAnglesDegrees = isControllingLiveRobot
        ? targetJointAnglesDegrees.liveRobot
        : targetJointAnglesDegrees.vizbot;

      if (jointAnglesDegrees) {
        return {
          jointAngles: convertJointAngles.fromDegrees(jointAnglesDegrees),
        };
      }
    }

    if (controlMode === 'toolControlTarget') {
      const pose = isControllingLiveRobot
        ? targetPose.liveRobot
        : targetPose.vizbot;

      if (pose) {
        return {
          tooltipPoint: convertEulerPose.toCartesian(pose),
        };
      }
    }

    return null;
  }, [
    controlMode,
    isControllingLiveRobot,
    targetJointAnglesDegrees,
    targetPose,
  ]);
}
