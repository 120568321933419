import type { ArmJointPositions } from '@sb/motion-planning';
import { roundToDecimalPlaces } from '@sb/utilities';

import {
  getCurrentJointAnglesDegrees,
  useMoveRobotViewContext,
} from '../../shared';

export default function useTargetJointAngles() {
  const {
    controlViewRoutineRunnerHandle,
    isControllingLiveRobot,
    setTargetJointAnglesDegrees,
    targetJointAnglesDegrees,
  } = useMoveRobotViewContext();

  const targetKey = isControllingLiveRobot ? 'liveRobot' : 'vizbot';

  const setTargetAngle = (
    index: number,
  ): React.Dispatch<React.SetStateAction<number>> => {
    return (targetAngle) => {
      setTargetJointAnglesDegrees((previousState) => {
        const previousTarget: ArmJointPositions | null =
          previousState[targetKey];

        const newTarget: ArmJointPositions = previousTarget
          ? [...previousTarget]
          : getCurrentJointAnglesDegrees(controlViewRoutineRunnerHandle)!;

        const newTargetAngle =
          typeof targetAngle === 'function'
            ? targetAngle(newTarget[index])
            : targetAngle;

        const roundedNewTargetAngle = roundToDecimalPlaces(newTargetAngle, 1);

        // avoid setting if no change (can compare with `===` because values have been rounded)
        if (roundedNewTargetAngle === newTarget[index]) {
          return previousState;
        }

        newTarget[index] = roundToDecimalPlaces(newTargetAngle, 1);

        return { ...previousState, [targetKey]: newTarget };
      });
    };
  };

  const resetAllTargetAngles = () => {
    setTargetJointAnglesDegrees((previousState) => {
      return {
        ...previousState,
        [targetKey]: null,
      };
    });
  };

  const setAllTargetJointAngles = (newTarget: ArmJointPositions) => {
    setTargetJointAnglesDegrees((previousState) => {
      return {
        ...previousState,
        [targetKey]: newTarget,
      };
    });
  };

  return {
    setAllTargetJointAngles,
    resetAllTargetAngles,
    setTargetAngle,
    targetJointAngles: targetJointAnglesDegrees[targetKey],
  };
}
