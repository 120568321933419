import type { ArmJointVelocities, JointNumber } from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';
import {
  InputLabel,
  RangeSpinner,
  useDualValueRangeSpinner,
} from '@sb/ui/components';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useIsRobotMoving,
} from '@sbrc/hooks';
import { JOINT_NAMES } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { JOINT_ANGLE_PRECISION, useMoveRobotViewContext } from '../../shared';

import styles from './JointSpinner.module.css';

const JOINT_ANGLE_SLIDER_STEP = 1;

// the angles are already rounded to 1dp; using `toFixed` to pad `5` to `5.0`
function angleToString(angle: number) {
  return angle.toFixed(JOINT_ANGLE_PRECISION);
}

export interface JointSpinnerProps {
  isDualValue: boolean;
  isJointSynced: boolean;
  jointAngle: number;
  jointLimit: { max: number; min: number };
  jointNumber: JointNumber;
  onTargetAngleChange: React.Dispatch<React.SetStateAction<number>>;
  targetAngle: number;
}

export default function JointSpinner({
  isDualValue,
  isJointSynced,
  jointAngle,
  jointLimit,
  jointNumber,
  onTargetAngleChange,
  targetAngle,
}: JointSpinnerProps) {
  const { controlViewRoutineRunnerHandle, isControllingLiveRobot, robot } =
    useMoveRobotViewContext();

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  };

  const { stopGuidedMode, runAdHocCommand } = useGuidedMode(routineRunnerArgs);

  const { isRunningAdHocCommand } = useIsRobotMoving(routineRunnerArgs);

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID: robot.id,
  });

  const onJointAngleChange = (isPositive: boolean) => {
    const handleJointAngleChange = async (): Promise<void> => {
      const speedProfile = await getAdHocSpeedProfile(
        robot.id,
        isControllingLiveRobot,
        isAdHocFullSpeed,
      );

      const jointRelativeSpeedProfile: SpeedProfile = {
        ...speedProfile,
        maxJointSpeeds: speedProfile.maxJointSpeeds.map((jointSpeed) => {
          // sign reflects direction
          return jointSpeed * (isPositive ? 1 : -1);
        }) as ArmJointVelocities,
      };

      return controlViewRoutineRunnerHandle.moveJointRelative(
        jointNumber,
        jointRelativeSpeedProfile,
      );
    };

    runAdHocCommand({ onRunCommand: handleJointAngleChange });
  };

  /** Shared props between the single slider and the dual value one. */
  const commonProps = {
    childrenBefore: <InputLabel>{JOINT_NAMES[jointNumber]}</InputLabel>,
    className: styles.jointSpinner,
    max: jointLimit.max,
    min: jointLimit.min,
    primaryValue: jointAngle,
    valueToString: angleToString,
  };

  /** Props exclusive to the dual value slider. */
  const dualValueJointSpinnerProps = useDualValueRangeSpinner({
    isEqual: isJointSynced,
    secondaryValue: targetAngle,
    step: JOINT_ANGLE_SLIDER_STEP,
    onChange: onTargetAngleChange,
    ...commonProps,
  });

  /** Don't allow to increase joint angles when it reaches its limits. */
  const isMaxLimitReached = jointAngle >= jointLimit.max;

  /** Don't allow to decrease joint angles when it reaches its limits. */
  const isMinLimitReached = jointAngle <= jointLimit.min;

  if (isDualValue) {
    return <RangeSpinner {...dualValueJointSpinnerProps} {...commonProps} />;
  }

  return (
    <RangeSpinner
      isPulseDisabled={!isRunningAdHocCommand}
      isSpinPlusDisabled={isAnotherSessionMovingRobot || isMaxLimitReached}
      isSpinMinusDisabled={isAnotherSessionMovingRobot || isMinLimitReached}
      onSpinnerRelease={stopGuidedMode}
      onSpinnerHold={onJointAngleChange}
      {...commonProps}
    />
  );
}
