import { isEqual } from 'lodash';
import { useEffect } from 'react';

import type { JointNumber } from '@sb/motion-planning';
import { Button, Loader } from '@sb/ui/components';
import {
  DownloadIcon,
  JointsIcon,
  ResetAllIcon,
  SwitcherIcon,
} from '@sb/ui/icons';
import {
  useFeatureFlag,
  useGuidedMode,
  useRobotJointAnglesDegrees,
  useRobotJointLimitsDegrees,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import { convertJointAngles } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import WidgetView from '../../../visualizer-view-shared/widget-panel/WidgetView';
import { useIsRobotSynced, useMoveRobotViewContext } from '../../shared';

import JointSpinner from './JointSpinner';
import useTargetJointAngles from './useTargetJointAngles';

import styles from './MoveRobotJointControlWidget.module.css';

export default function MoveRobotJointControlWidget() {
  const {
    controlViewRoutineRunnerHandle,
    isControllingLiveRobot,
    isLiveRobotConnected,
    robot,
    controlMode,
    setControlMode,
  } = useMoveRobotViewContext();

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const isDualValueMode = controlMode === 'jointControlDual';

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  };

  const otherRobotRoutineRunnerHandle = useRoutineRunnerHandle({
    robotID: robot.id,
    isVizbot: isControllingLiveRobot,
  });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(routineRunnerArgs);

  const jointAnglesDegrees = useRobotJointAnglesDegrees(routineRunnerArgs);

  const jointLimitsDegrees = useRobotJointLimitsDegrees(routineRunnerArgs);

  const {
    setTargetAngle,
    resetAllTargetAngles,
    targetJointAngles,
    setAllTargetJointAngles,
  } = useTargetJointAngles();

  const { isArmSynced } = useIsRobotSynced();

  const hasTargets = Boolean(targetJointAngles);

  // clear target angles when they equal the actual joint angles
  // note, no dependency array, this can run on every render
  useEffect(() => {
    if (
      // We only care about this when the dual value mode is enabled.
      isDualValueMode &&
      targetJointAngles &&
      // it is valid to use `isEqual` to compare joint angles because they are rounded to 1dp
      isEqual(jointAnglesDegrees, targetJointAngles)
    ) {
      resetAllTargetAngles();
    }
  });

  const onHoldApplyChanges = () => {
    if (targetJointAngles) {
      const moveRobotToTargetPosition = async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isControllingLiveRobot,
          isAdHocFullSpeed,
        );

        return controlViewRoutineRunnerHandle.moveToJointSpacePoint(
          convertJointAngles.fromDegrees(targetJointAngles),
          speedProfile,
        );
      };

      runAdHocCommand({ onRunCommand: moveRobotToTargetPosition });
    }
  };

  const buttonText = isControllingLiveRobot ? 'Visualizer' : 'Live Robot';

  const isDisabled = !isDualValueMode || !isLiveRobotConnected || isArmSynced;

  return (
    <WidgetView
      onClose={() => setControlMode(null)}
      headerTitle="Joints"
      headerIcon={<JointsIcon />}
      headerButton={
        <>
          <Button
            className={styles.headerButtonText}
            startIcon={<DownloadIcon className={styles.buttonIcon} />}
            label="Import positions"
            size="extraSmall"
            onClick={() => {
              const otherRobotTargetJointAngles =
                otherRobotRoutineRunnerHandle.getState()?.kinematicState
                  .jointAngles;

              if (otherRobotTargetJointAngles) {
                setAllTargetJointAngles(
                  convertJointAngles.toDegrees(otherRobotTargetJointAngles),
                );
              }
            }}
            disabled={isDisabled}
            variant="gray"
            data-testid="move-robot-control-widgets-tool-switch-import-pose-mode-button"
          >
            {buttonText}
          </Button>

          <Button
            startIcon={<ResetAllIcon className={styles.buttonIcon} />}
            label="Reset all joint targets"
            size="extraSmall"
            onClick={resetAllTargetAngles}
            disabled={!isDualValueMode || !hasTargets}
            variant="gray"
          />

          <Button
            startIcon={<SwitcherIcon className={styles.buttonIcon} />}
            label="Switch joint control mode"
            size="extraSmall"
            onClick={() =>
              setControlMode(
                isDualValueMode ? 'jointControlSingle' : 'jointControlDual',
              )
            }
            variant={isDualValueMode ? 'primary' : 'gray'}
            data-testid="move-robot-control-widgets-tool-switch-control-mode-button"
          />
        </>
      }
    >
      <div className={styles.container}>
        {!jointAnglesDegrees || !jointLimitsDegrees ? (
          <Loader className={styles.loader} />
        ) : (
          jointAnglesDegrees.map((jointAngle, index) => (
            <JointSpinner
              // eslint-disable-next-line react/no-array-index-key
              key={`joint-${index}-${
                isControllingLiveRobot ? 'live' : 'simBot'
              }`}
              isDualValue={isDualValueMode}
              isJointSynced={
                !targetJointAngles || jointAngle === targetJointAngles[index]
              }
              jointAngle={jointAngle}
              jointLimit={jointLimitsDegrees[index]}
              jointNumber={index as JointNumber}
              onTargetAngleChange={setTargetAngle(index)}
              targetAngle={targetJointAngles?.[index] ?? jointAngle}
            />
          ))
        )}
      </div>

      {isDualValueMode && (
        <div className={styles.bottomContainer}>
          <Button
            variant="blackPrimary"
            disabled={!targetJointAngles}
            isFullWidth
            onHold={onHoldApplyChanges}
            onRelease={stopGuidedMode}
            data-testid="move-robot-control-panel-widgets-joint-control-button"
          >
            Hold to Apply Changes
          </Button>
        </div>
      )}
    </WidgetView>
  );
}
