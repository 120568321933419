import cx from 'classnames';
import { useCallback, useEffect } from 'react';

import type {
  GripperState,
  GripperStateNonNull,
} from '@sb/integrations/gripper-general';
import { gripperStateToCommand } from '@sb/integrations/gripper-general';
import type { ArmJointPositions } from '@sb/motion-planning';
import type { CommandResult, KinematicState } from '@sb/routine-runner';
import { Button, Checkbox, RangeSpinner, Typography } from '@sb/ui/components';
import {
  BrakeIcon,
  CompletedIcon,
  GripperIcon,
  RobotIcon,
  SwapIcon,
  SyncIcon,
  VisualizerIcon,
} from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import {
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotStateKind,
  useVizbotRoutineRunnerHandle,
  useToast,
  useRobotUnbrake,
  useLiveRoutineRunnerHandle,
} from '@sbrc/hooks';
import type { RoutineRunnerHandle } from '@sbrc/services';

import { useConfirmNoHumansAndPayload } from '../../payload';
import getAdHocSpeedProfile from '../../visualizer-view-shared/getAdHocSpeedProfile';
import WidgetView from '../../visualizer-view-shared/widget-panel/WidgetView';
import type { SyncingRobot } from '../shared';
import {
  ROBOT_CONTROL_MODE_DEFAULT,
  useIsRobotSynced,
  useMoveRobotViewContext,
} from '../shared';

import styles from './ControlModeSyncPositionsView.module.css';

function getJointAngles(state?: KinematicState) {
  return state?.jointAngles;
}

function getGripperState(state?: KinematicState) {
  return state?.gripperState;
}

const ControlModeSyncPositionsView = () => {
  const {
    isControllingLiveRobot,
    isLiveRobotConnected,
    isRealRobotBraked,
    onSyncPositionComplete,
    robot,
    setControlMode,
    setIsControllingLiveRobot,
    setSyncingRobot,
    setTargetJointAnglesDegrees,
    syncingRobot,
  } = useMoveRobotViewContext();

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID: robot.id,
  });

  const realRoutineRunnerHandle = useLiveRoutineRunnerHandle({
    robotID: robot.id,
  });

  const vizbotRoutineRunnerHandle = useVizbotRoutineRunnerHandle();

  const { setToast } = useToast();

  const liveRobotGuidedMode = useGuidedMode({ robotID: robot.id });

  const { unbrakeRobot: unbrakeLiveRobot } = useRobotUnbrake({
    robotID: robot.id,
  });

  /**
   * Sometimes we display the move page as a modal to sync positions. When we open
   * the sync positions panel as a modal, `onSyncPositionComplete` will be defined and
   * some actions on this page should be disabled because they're not supported in the modal.
   */
  const isSyncPositionsModal = Boolean(onSyncPositionComplete);

  const realRoutineRunnerKind = useRobotStateKind({
    robotID: robot.id,
  });

  const {
    payloadRangeSpinnerProps,
    noHumansCheckboxProps,
    isNoHumansConfirmed,
    setTargetPayload,
  } = useConfirmNoHumansAndPayload({
    robotID: robot.id,
  });

  useEffect(() => {
    // set the target payload from vizbot - see https://linear.app/standardbots/issue/SW-1230
    const vizbotPayload =
      vizbotRoutineRunnerHandle.getState()?.configuration.payload.mass;

    if (vizbotPayload !== undefined) {
      setTargetPayload(vizbotPayload);
    }
  }, [vizbotRoutineRunnerHandle, setTargetPayload]);

  const { isArmSynced, isGripperSynced } = useIsRobotSynced();

  // Display error toast if the robot loses connection or if the robot is running a routine
  useEffect(() => {
    if (!isLiveRobotConnected) {
      setToast({
        kind: 'error',
        message:
          "The live robot's positions cannot be retrieved at this time, please make sure the live robot is connected",
      });
    }

    if (realRoutineRunnerKind === 'RoutineRunning') {
      setToast({
        kind: 'error',
        message:
          'Robot is currently running a routine, please pause to sync positions',
      });
    }
  }, [isLiveRobotConnected, realRoutineRunnerKind, setToast]);

  const onHoldAction = <T extends {}>(
    syncingType: SyncingRobot,
    getState: (kinematicState?: KinematicState) => T | undefined | null,
    onRunCommand: (
      handle: RoutineRunnerHandle,
      targetState: T,
    ) => Promise<CommandResult>,
    errorToast: string,
  ) => {
    return async () => {
      const sourceHandle = vizbotRoutineRunnerHandle;

      const targetHandle = realRoutineRunnerHandle;

      const sourceState = getState(sourceHandle.getState()?.kinematicState);

      if (!sourceState) {
        return;
      }

      setSyncingRobot(syncingType);

      await liveRobotGuidedMode.runAdHocCommand({
        onRunCommand: () => onRunCommand(targetHandle, sourceState),
        onError: () => {
          setToast({
            kind: 'error',
            message: errorToast,
          });
        },
      });

      setSyncingRobot(null);
    };
  };

  const onTapAction = <T extends {}>(
    getState: (kinematicState?: KinematicState) => T | undefined | null,
    onSetPosition: (targetState: T) => void,
  ) => {
    const liveRobotState = getState(
      realRoutineRunnerHandle.getState()?.kinematicState,
    );

    if (!liveRobotState) return;

    onSetPosition(liveRobotState);
  };

  const onSetArmAction = (jointAngles: ArmJointPositions) => {
    vizbotRoutineRunnerHandle.setJointPositions(jointAngles);
  };

  const onSetGripperAction = (gripperState: GripperState) => {
    vizbotRoutineRunnerHandle.setGripperState(gripperState);
  };

  const onReleaseAction = () => {
    liveRobotGuidedMode.stopGuidedMode();
    setSyncingRobot(null);
  };

  const syncArmAction = async (
    handle: RoutineRunnerHandle,
    jointAngles: ArmJointPositions,
  ): Promise<CommandResult> => {
    const speedProfile = await getAdHocSpeedProfile(
      robot.id,
      isControllingLiveRobot,
    );

    return handle.moveToJointSpacePoint(jointAngles, speedProfile);
  };

  const syncGripperAction = async (
    handle: RoutineRunnerHandle,
    gripperState: GripperState,
  ): Promise<CommandResult> => {
    return handle.actuateDevice({
      command: gripperStateToCommand(gripperState),
    });
  };

  /**
   * In some places (i.e. read screen state step configuration), we need
   * to open the move view modal to sync positions. In those cases, clicking
   * on the confirm should close the modal when the positions are synced.
   */
  const onConfirmPositionsAreSynced = useCallback(() => {
    if (onSyncPositionComplete) {
      onSyncPositionComplete();
    } else {
      setControlMode(ROBOT_CONTROL_MODE_DEFAULT);
    }
  }, [onSyncPositionComplete, setControlMode]);

  const syncComplete = isArmSynced && isGripperSynced && syncingRobot === null;

  /**
   * After the arm position is synced, we need to reset the target state. Otherwise,
   * the joint control widget would show as if the positions weren't synced.
   */
  useEffect(() => {
    if (isArmSynced) {
      setTargetJointAnglesDegrees({ liveRobot: null, vizbot: null });
    }
  }, [isArmSynced, setTargetJointAnglesDegrees]);

  return (
    <>
      {!syncComplete && (
        <WidgetView
          headerIcon={<SyncIcon />}
          headerTitle={
            isControllingLiveRobot
              ? 'Sync Live Robot Positions'
              : 'Sync Visualizer Positions'
          }
          onClose={() => setControlMode(null)}
          hasNoBorder
        >
          <div className={styles.section}>
            <Typography variant="medium" className={margin.bottom.medium}>
              To
              <strong>
                {isControllingLiveRobot
                  ? ` move Live ${robot.name} `
                  : ' move the Visualizer '}
              </strong>
              to
              {isControllingLiveRobot
                ? ' the Visualizer '
                : ` Live ${robot.name}’s `}
              arm and gripper positions,{' '}
              <strong>{isControllingLiveRobot ? 'hold down' : 'tap'}</strong>{' '}
              the button until the button becomes disabled.
            </Typography>

            <div
              className={cx(
                styles.fromToGrid,
                isControllingLiveRobot
                  ? margin.bottom.small
                  : margin.bottom.medium,
              )}
            >
              <div style={{ gridArea: 'fromLabel' }}>
                <Typography component="span" variant="medium">
                  {isControllingLiveRobot ? 'Move' : 'Reset'}:
                </Typography>
              </div>

              <div style={{ gridArea: 'toLabel' }}>
                <Typography component="span" variant="medium">
                  To:
                </Typography>
              </div>

              <Button
                disabled={isSyncPositionsModal}
                onClick={() => {
                  setIsControllingLiveRobot((prevState) => !prevState);
                }}
                variant="blackPrimary"
                startIcon={<SwapIcon />}
                style={{ gridArea: 'swapButton' }}
                data-testid="switch-sync-positions-widget-button"
              />
              <div
                className={styles.fromToValue}
                style={{
                  gridArea: isControllingLiveRobot ? 'toValue' : 'fromValue',
                }}
              >
                <VisualizerIcon size="small" />
                <Typography component="span" variant="medium" isBold>
                  Visualizer {isControllingLiveRobot ? 'Position' : ''}
                </Typography>
              </div>

              <div
                className={styles.fromToValue}
                style={{
                  gridArea: isControllingLiveRobot ? 'fromValue' : 'toValue',
                }}
              >
                <RobotIcon size="small" />
                <Typography component="span" variant="medium" isBold>
                  Live Robot {isControllingLiveRobot ? '' : 'Position'}
                </Typography>
              </div>
            </div>

            {isControllingLiveRobot && (
              <>
                <div className={styles.payloadForm}>
                  <Typography isBold isUppercase variant="extraSmall">
                    Live Robot Payload
                  </Typography>
                  <RangeSpinner {...payloadRangeSpinnerProps} />
                </div>
                <Checkbox
                  {...noHumansCheckboxProps}
                  className={cx(margin.top.small, margin.bottom.medium)}
                >
                  {isRealRobotBraked
                    ? noHumansCheckboxProps.children
                    : // override default label about 'no humans are within reach' if the robot is already unbraked
                      'I confirm that the current payload on the robot is correct.'}
                </Checkbox>

                {isRealRobotBraked ? (
                  <Button
                    disabled={!isNoHumansConfirmed}
                    isFullWidth
                    onClick={unbrakeLiveRobot}
                    variant="primary"
                    startIcon={<BrakeIcon />}
                  >
                    <strong>Unbrake</strong> Live Robot to Sync
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={
                        (syncingRobot === null && isArmSynced) ||
                        (syncingRobot !== null && syncingRobot !== 'live') ||
                        realRoutineRunnerKind === 'RoutineRunning' ||
                        !isNoHumansConfirmed ||
                        isAnotherSessionMovingRobot
                      }
                      isFullWidth
                      onHold={onHoldAction(
                        'live',
                        getJointAngles,
                        syncArmAction,
                        'Live robot arm position syncing has failed',
                      )}
                      onRelease={onReleaseAction}
                      startIcon={<RobotIcon />}
                      data-testid="move-live-arm-to-visualizer-arm-position-button"
                    >
                      <strong>Move Live Arm</strong> to Visualizer Arm Position
                    </Button>

                    <Button
                      className={margin.top.small}
                      disabled={
                        (syncingRobot === null && isGripperSynced) ||
                        (syncingRobot !== null &&
                          syncingRobot !== 'liveGripper') ||
                        realRoutineRunnerKind === 'RoutineRunning' ||
                        !isNoHumansConfirmed ||
                        isAnotherSessionMovingRobot
                      }
                      isFullWidth
                      onHold={onHoldAction<GripperStateNonNull>(
                        'liveGripper',
                        getGripperState,
                        syncGripperAction,
                        'Live robot gripper position syncing has failed',
                      )}
                      onRelease={onReleaseAction}
                      startIcon={<GripperIcon />}
                      data-testid="move-live-gripper-to-visualizer-gripper-position-button"
                    >
                      <strong>Move Live Gripper</strong> to Visualizer Gripper
                      Position
                    </Button>
                  </>
                )}
              </>
            )}

            {!isControllingLiveRobot && (
              <>
                <Button
                  isFullWidth
                  disabled={
                    (syncingRobot === null && isArmSynced) ||
                    (syncingRobot !== null && syncingRobot !== 'vizbot') ||
                    realRoutineRunnerKind === 'RoutineRunning' ||
                    isAnotherSessionMovingRobot
                  }
                  onClick={() => onTapAction(getJointAngles, onSetArmAction)}
                  variant="secondary"
                  startIcon={<RobotIcon />}
                  data-testid="reset-visualizer-arm-to-live-arm-position-button"
                >
                  <strong>Reset Visualizer Arm</strong> to Live Arm Position
                </Button>

                <Button
                  className={margin.top.small}
                  isFullWidth
                  disabled={
                    (syncingRobot === null && isGripperSynced) ||
                    (syncingRobot !== null &&
                      syncingRobot !== 'vizbotGripper') ||
                    realRoutineRunnerKind === 'RoutineRunning' ||
                    isAnotherSessionMovingRobot
                  }
                  onClick={() =>
                    onTapAction<GripperStateNonNull>(
                      getGripperState,
                      onSetGripperAction,
                    )
                  }
                  variant="secondary"
                  startIcon={<GripperIcon />}
                  data-testid="reset-visualizer-gripper-to-live-gripper-position-button"
                >
                  <strong>Reset Visualizer Gripper</strong> to Live Gripper
                  Position
                </Button>
              </>
            )}
          </div>
        </WidgetView>
      )}

      {syncComplete && (
        <WidgetView
          onClose={onConfirmPositionsAreSynced}
          className={styles.widget}
        >
          <div className={styles.section}>
            <CompletedIcon className={styles.completedIcon} />

            <Typography
              variant="medium"
              className={margin.bottom.medium}
              textAlign="center"
            >
              {isSyncPositionsModal
                ? `Live ${robot.name} is in the target position`
                : `The Visualizer and Live ${robot.name} are fully synced.`}
            </Typography>

            <Button
              isFullWidth
              variant="primary"
              onClick={onConfirmPositionsAreSynced}
              data-testid="return-to-live-robot-button"
            >
              {isSyncPositionsModal
                ? 'Return'
                : `Return to ${
                    isControllingLiveRobot ? 'Live Robot' : 'Visualizer'
                  }`}
            </Button>
          </div>
        </WidgetView>
      )}
    </>
  );
};

export default ControlModeSyncPositionsView;
